import React, { useEffect, useState } from "react";
import Container from "../Container/Container";
import { useMenuQuery } from "../../hooks/useMenuQuery";
import NewLogo from "../../images/logo.svg"
import { Link } from "gatsby"
import MegaMenu from "./MegaMenu/MegaMenu"
import Navigation from "../Navigation/Navigation"
import nvm from '../../images/nvm.png';

export default function NewHeader({data}){
    
    const { site, wpMenu, allWpVestiging } = useMenuQuery()
    const [openMenu, setOpenMenu] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [selectedMenu, setSelectedMenu] = useState("")
    const [width, setWidth] = useState()

    const [scrollDirection, setScrollDirection] = useState(null); // null, "up", or "down"
    const [lastScrollY, setLastScrollY] = useState(0);

    const handleMouseEnter = data => {
        setSelectedMenu(data)
        setIsVisible(true)
    }
    
    const handleMouseLeave = () => {
        setSelectedMenu(``)
        setIsVisible(false)
    }

    const clickHandler = () => {
        setOpenMenu(v => !v)
    }

    useEffect(() => {
        setWidth(window.innerWidth)
      }, [])

      useEffect(() => {
        const handleScroll = () => {
          const currentScrollY = window.scrollY;
    
          if (currentScrollY > lastScrollY) {
            setScrollDirection('down');
          } else if (currentScrollY < lastScrollY) {
            setScrollDirection('up');
          }
    
          setLastScrollY(currentScrollY);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [lastScrollY]);
    

    return(
        <div className={`header__container ${scrollDirection === 'up' ? 'header__container--visible' : ''}`}>
        <header className="header bg-blue">
            <div className="container">
                <Link to="/" className={`logo__container`}>
                <img className="logo" src={NewLogo} alt={site.siteMetadata.title} />
                </Link>
                <div className={`${openMenu ? `lg:relative fixed left-0` : `lg:relative lg:left-0 fixed -left-full`} navigation-container`}>
                    <Navigation data={wpMenu} mouseEnter={handleMouseEnter} mouseLeave={handleMouseLeave} closeMenu={clickHandler} width={width} visible={openMenu} />
                </div>
                <img className="nvm" src={nvm} />
                <div id={`nav-icon1`} className={`${openMenu ? "open" : ""} lg:hidden z-50`} onClick={clickHandler}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </header>
        <MegaMenu menu={wpMenu} selected={selectedMenu} isVisible={isVisible} mouseEnter={handleMouseEnter} mouseLeave={handleMouseLeave} />
        </div>
    )
}